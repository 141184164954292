import React from "react"
import styled from "styled-components"
import CallToAction from "./callToAction"
import RichContentBlockLabel from "./richContentBlockLabel"
import RichContentBlockText from "./richContentBlockText"
import Benefits from "./benefits"
import { Container } from "./utils"
import { Box } from "@theme-ui/components"
import { Heading } from "../components/typography"
import DesignTokens from "./designTokens"
import VideoEmbed from "./videoEmbed"
import { Grid } from "theme-ui"

const RichContentBlock = ({ content }) => {
  const labelColor =
    content.backgroundColor.hex === "#ffffff"
      ? DesignTokens.colors.primary[500]
      : DesignTokens.colors.white
  const theme = content.backgroundColor.hex === "#ffffff" ? "light" : "dark"
  return (
    <Wrapper
      image={content.backgroundImage ? content.backgroundImage.url : null}
      color={content.backgroundColor.hex}
    >
      <Container>
        <Grid gap={[3, 6]} columns={[1, 1, "1fr 1fr"]}>
          <Main
            color={content.backgroundColor.hex}
            alignment={content.alignment}
          >
            {content.label && (
              <RichContentBlockLabel
                icon={content.icon}
                label={content.label}
                color={labelColor}
              />
            )}
            {content.title && (
              <Box
                sx={{
                  "h1,h2,h3,h4,h5,h6": {
                    mb: 3,
                  },
                  "h1,h2": {
                    fontSize: [4, 5],
                  },
                }}
                dangerouslySetInnerHTML={{ __html: content.title }}
              />
            )}
            <RichContentBlockText html={content.text} theme={theme} />
            {content.callToAction && (
              <CallToAction cta={content.callToAction} theme={theme} />
            )}
          </Main>
          <Box
            sx={{
              marginTop: [5, 5, 5, 0],
              img: {
                objectFit: "contain",
                width: "auto",
                height: "auto",
              },
              order: content.alignment === "left" ? 2 : 1,
            }}
          >
            {content.image && (
              <img src={content.image.url} alt={content.image.alt} />
            )}
            {content.video && <VideoEmbed code={content.video} />}
          </Box>
        </Grid>
        {content.benefits.length > 0 && (
          <BenefitsContainer>
            <Benefits benefits={content.benefits} theme={theme} />
          </BenefitsContainer>
        )}
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  background: ${props => props.color} url(${props => props.image});
  background-size: cover;
  background-position: top;
  padding: 3rem 0;
  @media screen and (max-width: 767px) {
    padding: 4rem 0;
  }
`

const BenefitsContainer = styled.section`
  margin-top: ${DesignTokens.spacing[10]};
`

const Main = styled.div`
  color: ${props =>
    props.color === "#ffffff" ? "inherit" : DesignTokens.colors.white};
  order: ${props => (props.alignment === "left" ? 1 : 2)};
  & > * {
    margin-bottom: ${DesignTokens.spacing[6]};
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`
export default RichContentBlock
