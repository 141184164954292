import React from "react"
import { Box } from "@theme-ui/components"

const VideoEmbed = ({ code }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "0",
        paddingBottom: "56.25%",
        iframe: {
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        },
      }}
      dangerouslySetInnerHTML={{ __html: code }}
    />
  )
}

export default VideoEmbed
