import React from 'react'
import styled from 'styled-components'
import { Container } from './utils'
import RichContentBlockText from './richContentBlockText'

const TextBlock = ({text, theme}) => (
<Wrapper>
  <Container>
    <RichContentBlockText html={text} />
  </Container>
</Wrapper>
)
const Wrapper = styled.div`
    padding: 3rem 0;
`

export default TextBlock