import React from "react"
import styled from "styled-components"
import SectionHeader from "./sectionHeader"
import { Container } from "./utils"
import DesignTokens from "./designTokens"

const TitleBlock = ({ content }) => (
  <Wrapper>
    <Container>
      <SectionHeader label={content.label} title={content.title} text={content.text} />
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  text-align: center;
  padding: ${DesignTokens.spacing[7]} 0;
`

export default TitleBlock
