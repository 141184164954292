import React from "react"
import { ItemsList } from "./utils"
import { Lead } from "./typography"
import styled from "styled-components"
import DesignTokens from "./designTokens"

const BenefitsGrid = ({ benefits, theme }) => (
  <CustomList theme={theme}>
    {benefits.map(benefit => (
      <li key={benefit.id}>
        <Icon>
          {benefit.icon && (
            <img src={benefit.icon.url} alt={benefit.icon.alt} />
          )}
          <Lead color={DesignTokens.colors.white}>{benefit.title}</Lead>
        </Icon>
        <div style={{color: "white"}} dangerouslySetInnerHTML={{ __html: benefit.text }} />
      </li>
    ))}
  </CustomList>
)

const Icon = styled.div`
  display: flex;
  flex-direction: row;
  img {
    margin-right: ${DesignTokens.spacing[5]};
  }
`

const CustomList = styled(ItemsList)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: left;
  margin-top: ${DesignTokens.spacing[10]};
  color: ${props =>
    props.theme === "dark"
      ? DesignTokens.colors.white
      : DesignTokens.colors.black};
  & > li {
    background-color: ${DesignTokens.colors.primary[500]};
    padding: ${DesignTokens.spacing[10]} ${DesignTokens.spacing[8]};
    border-radius: ${DesignTokens.borderRadius};
    width: calc(96% / 2);
    margin-right: 2%;
    margin-top: 2%;
    &:nth-child(-n + 2) {
      margin-top: 0;
    }
    &:nth-child(even) {
      margin-right: 0;
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[6]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    & > li {
      width: 100%;
      margin: 0 0 4% 0;
    }
  }
`

export default BenefitsGrid
