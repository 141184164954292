import React from "react"
import { ItemsList } from "./utils"
import { Lead } from "./typography"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import RichContentBlockText from "./richContentBlockText"

const Benefits = ({ benefits, theme }) => {
  return (
    <CustomList theme={theme} length={benefits.length}>
      {benefits.map(benefit => (
        <li key={benefit.id}>
          {benefit.icon && (
            <img src={benefit.icon.url} alt={benefit.icon.alt} />
          )}
          <Lead>{benefit.title}</Lead>
          <hr />
          {benefit.text && (
            <>
              <RichContentBlockText html={benefit.text} theme={theme} />
            </>
          )}
        </li>
      ))}
    </CustomList>
  )
}

const CustomList = styled(ItemsList)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: ${props => (props.length > 5 ? "wrap" : "nowrap")};
  color: ${DesignTokens.colors.black};
  & > li {
    text-align: left;
    margin-right: 5%;
    flex: ${props => (props.length > 5 ? "none" : "1 1 0px")};
    width: ${props => (props.length > 5 ? "20%" : null)};
    &:last-child {
      margin-right: 0;
    }
    margin-bottom: ${DesignTokens.spacing[6]};
    & > * {
      margin-bottom: ${DesignTokens.spacing[6]};
      &:last-child {
        margin-bottom: 0;
      }
    }
    hr {
      border-bottom: 1px dashed ${DesignTokens.colors.black};
      width: 50%;
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: space-between;
    & > li {
      margin-right: 0;
      flex: none;
      width: 45%;
    }
  }
`

export default Benefits
