import React from "react"
import styled from "styled-components"
import { Container } from "@theme-ui/components"
import SectionHeader from "./sectionHeader"
import Benefits from "./benefits"
import BenefitsGrid from "./benefitsGrid"
import CallToAction from "./callToAction"

const BenefitsBlock = ({ content }) => {
  const theme = content.backgroundColor.hex === "#ffffff" ? "light" : "dark"
  return (
    <Wrapper color={content.backgroundColor.hex}>
      <Container
        sx={{
          "& > *": {
            marginBottom: 5,
            "&:last-child": {
              marginBottom: 0,
            },
          },
        }}
      >
        <SectionHeader
          label={content.label}
          title={content.title}
          theme={theme}
        />
        {content.benefits && content.layout === "column" ? (
          <Benefits benefits={content.benefits} theme={theme} />
        ) : (
          <BenefitsGrid benefits={content.benefits} />
        )}
        {content.callToAction && (
          <CallToAction cta={content.callToAction} theme={theme} />
        )}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${props => props.color};
  padding: 1rem 0;
  text-align: center;
`

export default BenefitsBlock
