import React from "react"
import styled from "styled-components"
import { Caption } from "../components/typography"
import { Box } from "@theme-ui/components"
import DesignTokens from "./designTokens"

const RichContentBlockLabel = ({ icon, label, color }) => (
  <Wrapper>
    {icon && <img src={icon.url} alt={icon.alt} />}
    <Box sx={{ color: color }} dangerouslySetInnerHTML={{ __html: label }} />
  </Wrapper>
)
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 44px;
    margin-right: ${DesignTokens.spacing[6]};
  }
`

export default RichContentBlockLabel
