import React from "react"
import styled from "styled-components"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import DesignTokens from "./designTokens"
import { Container } from "./utils"
import RichContentBlock from "./richContentBlock"
import TextBlock from "./textBlock"

const CustomTabs = ({ tabs, kind }) => {
  return (
    <Wrapper>
      <Container>
        <Tabs>
          <StyledTabList>
            {tabs.map(tab => (
              <Tab key={tab.id}>{tab.title.replace(/<[^>]*>?/gm, "")}</Tab>
            ))}
          </StyledTabList>
          {tabs.map(tab => (
            <TabPanel key={tab.id}>
              {kind === "richContent" ? (
                <RichContentBlock content={tab} />
              ) : (
                <TextBlock text={tab.text} theme="dark" />
              )}
            </TabPanel>
          ))}
        </Tabs>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${DesignTokens.spacing[6]} 0;
  background-color: ${DesignTokens.colors.white};
`

const StyledTabList = styled(TabList)`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${DesignTokens.colors.black};
  & > li {
    color: ${DesignTokens.colors.primary[500]};
    padding: ${DesignTokens.spacing[5]};
    border-bottom: 1px solid ${DesignTokens.colors.black};
    /* flex: 1; */
    &:hover {
      cursor: pointer;
      border-bottom: 1px solid ${DesignTokens.colors.primary[500]};
      color: ${DesignTokens.colors.primary[500]};
    }
    &.react-tabs__tab--selected {
      color: ${DesignTokens.colors.primary[500]};
      border-bottom: 3px solid ${DesignTokens.colors.primary[500]};
    }
  }
  @media screen and (max-width: 767px) {
    overflow-x: scroll;
    justify-content: start;
  }
`

export default CustomTabs
