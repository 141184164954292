import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Caption, Heading } from "./typography"
import RichContentBlockText from "./richContentBlockText"

const SectionHeader = ({ title, label, text, theme }) => {
  const color =
    theme === "dark" ? DesignTokens.colors.white : DesignTokens.colors.black
  const captionColor =
    theme === "dark"
      ? DesignTokens.colors.white
      : DesignTokens.colors.primary[500]
  return (
    <Section>
      {label && <Caption color={captionColor}>{label}</Caption>}
      {title && <Heading color={color}>{title}</Heading>}
      {text && <RichContentBlockText html={text} theme={theme} />}
    </Section>
  )
}

const Section = styled.section`
  @media screen and (max-width: 767px) {
    text-align: left;
  }

  & > * {
    margin-bottom: ${DesignTokens.spacing[5]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default SectionHeader
